import React, { useState } from "react";
import { phases_chantiers } from "~/utils/refs";

const Chantiers = (props) => {
  /* Le state permettant de gérer la recherche */
  const [search, setSearch] = useState("");
  const selectedChantier = props.selectedChantier;
  const setSelectedChantier = props.setSelectedChantier;
  const chantiers = props.chantiers ? props.chantiers : [];

  const listChantiers = () => {
    return chantiers.map((chantier) => {
      const found =
        chantier.title.toLowerCase().includes(search.toLowerCase()) ||
        chantier.address.toLowerCase().includes(search.toLowerCase()) ||
        chantier.phase.toLowerCase().includes(search.toLowerCase()) ||
        chantier.city.toLowerCase().includes(search.toLowerCase());
      if (found)
        return (
          <div
            onClick={() =>
              setSelectedChantier(
                selectedChantier != chantier.id ? chantier.id : null
              )
            }
            style={{ backgroundColor: `${chantier.color}` }}
            className={`${
              chantier.id == selectedChantier && "border-2 border-blue-500"
            } cursor-pointer rounded-lg shadow-xl flex flex-row items-center p-2 m-2 text-sm`}
          >
            <div className="text-center">
              <span className="font-bold ">{chantier.title}</span>
              <p className="line-clamp-3">
                {chantier.address} {chantier.city}
              </p>
            </div>
          </div>
        );
    });
  };
  return (
    <div className="h-fit my-2">
      <select
        data-te-select-initid="phase"
        name="phase"
        type="text"
        className="appearance-none rounded-none relative block
                  w-full px-3 py-2 border border-gray-300
                  placeholder-gray-500 text-gray-900 rounded-t-md
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm mb-2"
        //placeholder="xxx"
        onChange={(e) => setSearch(e.target.value)}
      >
        <option value="">Toutes les phases</option>
        {phases_chantiers.map((phase) => {
          return <option value={phase.name}>{phase.name}</option>;
        })}
      </select>
      <input
        className="rounded-md border-sky-600"
        type="text"
        placeholder="Rechercher"
        onChange={(e) => setSearch(e.target.value)}
      />
      {listChantiers()}
    </div>
  );
};

export default Chantiers;
