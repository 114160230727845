import { useState } from "react";
import { protectedAPI } from "./base";
import useSWR from 'swr'

export class ChantiersService {

    useGetAll() {
        const fetcher = url => protectedAPI.get(url).then(res => res.data)

        const { data, error, isLoading, isValidating, mutate } = useSWR('/chantiers', fetcher)

        return {
            data: data,
            error,
            isLoading: isLoading || isValidating,
            callToAction: mutate
        }
    }

    useGetOne(id) {
        const fetcher = url => protectedAPI.get(url).then(res => res.data)

        const { data, error, isLoading, mutate } = useSWR('/chantiers/' + id, fetcher)

        return {
            data,
            error,
            isLoading,
            callToAction: mutate
        }
    }

    useDelete(id) {

        const [data, setData] = useState(false)
        const [error, setError] = useState(null)
        const [isLoading, setIsLoading] = useState(false)

        function deleteFn() {
            setIsLoading(true)
            setError(null)

            if (!id) setError({message: "L'id précisé est incorrect"})

            protectedAPI.delete('/chantiers/' + id)
            .then(() => {
                setData(true)
            })
            .catch((error) => {
                setError(error)
            })
            .finally(() => {
                setIsLoading(false)
            })

        }

        return {
            data,
            error,
            isLoading,
            callToAction: deleteFn
        }
    }

    useUpdate(id, updateObj) {

        const [data, setData] = useState({})
        const [error, setError] = useState(null)
        const [isLoading, setIsLoading] = useState(false)

        function update() {
            setIsLoading(true)
            setError(null)

            if (!id) setError({message: "L'id précisé est incorrect"})

            protectedAPI.put('/chantiers/' + id, updateObj)
            .then((response) => {
                setData(response.data)
            })
            .catch((error) => {
                setError(error)
            })
            .finally(() => {
                setIsLoading(false)
            })

        }

        return {
            data,
            error,
            isLoading,
            callToAction: update
        }
    }

    useCreate(newObj) {

        const [data, setData] = useState(false)
        const [error, setError] = useState(null)
        const [isLoading, setIsLoading] = useState(false)

        function create() {
            setIsLoading(true)
            setError(null)

            protectedAPI.post('/chantiers', newObj)
            .then((response) => {
                setData(response.data)
            })
            .catch((error) => {
                setError(error)
            })
            .finally(() => {
                setIsLoading(false)
            })

        }

        return {
            data,
            error,
            isLoading,
            callToAction: create
        }
    }

}