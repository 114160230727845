import { Outlet, Link } from "react-router-dom";
import { RouteGuard } from "~/components/RouteGuard";
import { useEffect, useState } from "react";
import { useModals } from "~/router";

export default function Layout() {

    /* Le hook permettant de changer le header et le title de la page */
    const [pageTitle, setPageTitle] = useState('Sans nom')

    /* Le hook permettant de connaitre l'etat des modals (open par exemple) */
    const modals = useModals()

    /* Lorsque l'on met à jour le titre, on change aussi le document.title */
    useEffect(() => {
        document.title = pageTitle
    }, [pageTitle])

    return (
        <RouteGuard>
            {/* Le route guard permet de vérifier que l'utilisateur est authentifié */}
            <header className="">
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900">{pageTitle}</h1>
                </div>
            </header>
            <main>
            {/* Le blur permet de flouter le fond lorsque la modal est ouverte */}
            <div className={`mx-auto py-6 px-2 ${modals.current != '' ? 'blur' : ''}`}>
                {/* Le context à la outlet permet de changer le titre */}
                <Outlet context={[pageTitle, setPageTitle]} />
            </div>
            </main>
        </RouteGuard>
    )
}