import { UserService } from "~/services/userService";
import { Link, Navigate, useModals } from "~/router";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import {
  ArrowDownOnSquareIcon,
  ArrowRightIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import { BeatLoader } from "react-spinners";
import { RolesService } from "~/services/rolesService";

export default function Index() {
  // State servant au stockage des données de la personnes à créer
  const [stateData, setstateData] = useState({});
  const [userId, setUserId] = useState(0);

  /* le hook use modal permet d'ouvrir la modal de suppression */
  const modals = useModals();

  const userInitial = {
    username: "",
    first_name: "",
    last_name: "",
    subcontractor: false,
    phone : "",
    daily_rate: 0,
    on_planning: true,
    password: "",
    enabled: true,
    role_id: null,
    domains: []
  };

  /* le service d'API et le hook permettant de récupérer toutes les unités DMO */
  const userService = new UserService();
  const {
    data: apiGetData,
    error: apiGetError,
    isLoading: apiGetIsLoading,
    callToAction: apiGetHandler,
  } = userService.useGetAll();

  /* le service d'API et le hook permettant de créer un utilisateur */
  const {
    data: apiCreateData,
    error: apiCreateError,
    isLoading: apiCreateIsLoading,
    callToAction: apiCreateHandler,
  } = userService.useCreate(stateData);

  /* le service d'API et le hook permettant de mettre à jour un utilisateur */
  const {
    data: apiUpdateData,
    error: apiUpdateError,
    isLoading: apiUpdateIsLoading,
    callToAction: apiUpdateHandler,
  } = userService.useUpdate(userId, stateData);

  /* le service d'API et le hook permettant de récupérer tous les roles */
  const rolesService = new RolesService();
  const {
    data: apiGetDataRoles,
    error: apiGetErrorRoles,
    isLoading: apiGetIsLoadingRoles,
    callToAction: apiGetHandlerRoles,
  } = rolesService.useGetAll();

  /* Mise à jour de la liste des utilisateurs après ajout ou update */
  useEffect(() => {
    apiGetHandler();
    setstateData(userInitial);
  }, [apiCreateData, apiUpdateData]);

  /* Le hook permettant de changer le titre de la page et le useEffect qui le fait */
  const [_, setPageTitle] = useOutletContext();

  useEffect(() => {
    setPageTitle("Administration - Utilisateurs");
  }, []);

  /* Si erreur 401 not authenticated, on redirige vers la page de logout */
  if (apiGetError) {
    if (apiGetError.response && apiGetError.response.status === 401)
      return <Navigate to="/logout" />;
  }

  useEffect(() => {
    setstateData(userInitial)
  }, [apiCreateData])

  /* Render using table */
  return (
    <>
      <div className="w-full md:flex mt-5 text-center bg-gray-200 shadow-md my-5">
        <div className="w-full mx-3 mb-3 gap-3">
          {/*Formulaire d'update ou d'ajout */}
          <h1 className="w-full bg-slate-100 text-left text-2xl font-semibold uppercase my-5 px-5 py-2">
            {userId == 0 ? "Ajouter un utilisateur" : "Editer un utilisateur"}
          </h1>
          <div className="flex">
            {/*Volet de gauche du formulaire*/}
            <div className="basis-1/2 flex-col">
              <div className="flex">
                <label className="py-2 w-full" htmlFor="username">
                  Login
                </label>
                <input
                  id="username"
                  name="username"
                  type="text"
                  className="appearance-none rounded-none relative block
                  w-full px-3 py-2 border border-gray-300
                  placeholder-gray-500 text-gray-900 rounded-t-md
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                  //placeholder="xxx"
                  value={stateData.username}
                  onChange={(e) =>
                    setstateData({
                      ...stateData,
                      username: e.target.value,
                    })
                  }
                />
              </div>
              <div className="flex">
                <label className="py-2 w-full" htmlFor="first_name">
                  Prénom
                </label>
                <input
                  id="first_name"
                  name="first_name"
                  type="text"
                  className="appearance-none rounded-none relative block
                  w-full px-3 py-2 border border-gray-300
                  placeholder-gray-500 text-gray-900 rounded-t-md
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                  //placeholder="xxx"
                  value={stateData.first_name}
                  onChange={(e) =>
                    setstateData({
                      ...stateData,
                      first_name: e.target.value,
                    })
                  }
                />
              </div>
              <div className="flex">
                <label className="py-2 w-full" htmlFor="phone">
                  Téléphone
                </label>
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  className="appearance-none rounded-none relative block
                  w-full px-3 py-2 border border-gray-300
                  placeholder-gray-500 text-gray-900 rounded-t-md
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                  //placeholder="xxx"
                  value={stateData.phone}
                  onChange={(e) =>
                    setstateData({
                      ...stateData,
                      phone: e.target.value,
                    })
                  }
                />
              </div>
              <div className="flex">
                <label className="py-2 w-full" htmlFor="role_id">
                  Role
                </label>
                <select
                  data-te-select-initid="role_id"
                  name="role_id"
                  type="text"
                  className="appearance-none rounded-none relative block
                  w-full px-3 py-2 border border-gray-300
                  placeholder-gray-500 text-gray-900 rounded-t-md
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                  //placeholder="xxx"
                  onChange={(e) =>
                    setstateData({
                      ...stateData,
                      role_id: e.target.value != "" ? e.target.value : null,
                    })
                  }
                >
                  <option selected={stateData.role_id == null} value={null}></option>
                  {Array.isArray(apiGetDataRoles) &&
                    apiGetDataRoles.map((role) => {
                      return (
                        <option
                          selected={userId != 0 && stateData.role_id == role.id}
                          value={role.id}
                        >
                          {role.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="flex">
                <label className="py-2 w-full" htmlFor="daily_rate">
                  Taux Journalier
                </label>
                <input
                  id="daily_rate"
                  name="daily_rate"
                  type="text"
                  className="appearance-none rounded-none relative block
                  w-full px-3 py-2 border border-gray-300
                  placeholder-gray-500 text-gray-900 rounded-t-md
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                  //placeholder="xxx"
                  value={stateData.daily_rate}
                  onChange={(e) =>
                    setstateData({
                      ...stateData,
                      daily_rate: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            {/*Volet de droite du formulaire*/}
            <div className="basis-1/2 flex-col">
              <div className="flex">
                <label className="py-2 w-full" htmlFor="password">
                  Mot de passe
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  className="appearance-none rounded-none relative block
                  w-full px-3 py-2 border border-gray-300
                  placeholder-gray-500 text-gray-900 rounded-t-md
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                  //placeholder="xxx"
                  value={stateData.password}
                  onChange={(e) =>
                    setstateData({
                      ...stateData,
                      password: e.target.value,
                    })
                  }
                />
              </div>
              <div className="flex">
                <label className="py-2 w-full" htmlFor="last_name">
                  Nom
                </label>
                <input
                  id="last_name"
                  name="last_name"
                  type="text"
                  className="appearance-none rounded-none relative block
                  w-full px-3 py-2 border border-gray-300
                  placeholder-gray-500 text-gray-900 rounded-t-md
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                  //placeholder="xxx"
                  value={stateData.last_name}
                  onChange={(e) =>
                    setstateData({
                      ...stateData,
                      last_name: e.target.value,
                    })
                  }
                />
              </div>
              <div className="flex">
                <label className="py-2 w-full" htmlFor="on_planning">
                  Visible sur le planning
                </label>
                <div className="w-full">
                  <input
                    id="on_planning"
                    name="on_planning"
                    type="checkbox"
                    className="
                  border border-gray-300
                  placeholder-gray-500 text-gray-900 
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                    //placeholder="xxx"
                    checked={stateData.on_planning}
                    onChange={(e) =>
                      setstateData({
                        ...stateData,
                        on_planning: e.target.checked ? true : false,
                      })
                    }
                  />
                </div>
              </div>
              <div className="flex">
                <label className="py-2 w-full" htmlFor="subcontractor">
                  Sous-traitant
                </label>
                <div className="w-full">
                  <input
                    id="subcontractor"
                    name="subcontractor"
                    type="checkbox"
                    className="
                  border border-gray-300
                  placeholder-gray-500 text-gray-900 
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                    //placeholder="xxx"
                    checked={stateData.subcontractor}
                    onChange={(e) =>
                      setstateData({
                        ...stateData,
                        subcontractor: e.target.checked ? true : false,
                      })
                    }
                  />
                </div>
              </div>
              <div className="flex">
                <label className="py-2 w-full" htmlFor="enabled">
                  Actif
                </label>
                <div className="w-full">
                  <input
                    id="enabled"
                    name="enabled"
                    type="checkbox"
                    className="
                  border border-gray-300
                  placeholder-gray-500 text-gray-900 
                  focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm"
                    //placeholder="xxx"
                    checked={stateData.enabled}
                    onChange={(e) =>
                      setstateData({
                        ...stateData,
                        enabled: e.target.checked ? true : false,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="justify-center flex gap-2">
            <button
              className={`rounded-md border border-yellow-600 hover:bg-yellow-600 hover:text-white transition-colors duration-500 p-2 my-5`}
              onClick={() => {
                setstateData(userInitial);
                setUserId(0);
              }}
              hidden={userId == 0}
            >
              <XCircleIcon className="h-5 inline" /> Annuler
            </button>
            <button
              className={`rounded-md border border-green-600 ${
                apiCreateIsLoading || apiUpdateIsLoading
                  ? "bg-green-200"
                  : "hover:bg-green-600 hover:text-white"
              } transition-colors duration-500 p-2 my-5`}
              onClick={userId == 0 ? apiCreateHandler : apiUpdateHandler}
              disabled={apiCreateIsLoading || apiUpdateIsLoading}
            >
              <ArrowDownOnSquareIcon className="h-5 inline" /> Enregistrer
            </button>
            <button
              className={`rounded-md border border-red-600 hover:bg-red-600 hover:text-white transition-colors duration-500 p-2 my-5`}
              onClick={(e) =>
                modals.open("/administration/delete", {
                  state: { data: stateData },
                })
              }
              hidden={!userId}
            >
              <TrashIcon className="h-5 inline" /> Supprimer
            </button>
          </div>
        </div>
      </div>

      <div className="xl:flex">
        <div className="w-full">
          <BeatLoader size={10} loading={apiGetIsLoading} className="mx-auto" />
          {/* L'erreur si présente */}
          <h1 className="text-red-600 w-full text-center">
            {apiGetError && apiGetError.message}
          </h1>
          {/* La table contenant les données */}
          <table
            className={`table-auto w-full border border-collapse rounded-xl ${
              apiGetIsLoading && "blur-sm"
            }`}
          >
            <thead>
              <tr>
                <th className="border border-collapse py-1">Login</th>
                <th className="border border-collapse">Prénom</th>
                <th className="border border-collapse">Nom</th>
                <th className="border border-collapse">Role</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(apiGetData) &&
                apiGetData.map((user, i) => {
                  return (
                    <tr
                      key={user.id}
                      className={`${
                        i % 2 === 0 && "bg-sky-100"
                      } hover:bg-sky-200 cursor-pointer ${
                        user.enabled === false && "font-italic text-gray-CCC"
                      }`}
                      onClick={() => {
                        setstateData({
                          username: user.username,
                          first_name: user.first_name,
                          last_name: user.last_name,
                          subcontractor: user.subcontractor,
                          phone : user.phone,
                          daily_rate: user.daily_rate,
                          on_planning: user.on_planning,
                          password: user.password,
                          enabled: user.enabled,
                          role_id: user.role_id,
                          domains: user.domains
                        });
                        setUserId(user.id);
                      }}
                    >
                      <td className="border border-collapse text-center py-1">
                        {user.username}
                      </td>
                      <td className="border border-collapse text-center">
                        {user.first_name}
                      </td>
                      <td className="border border-collapse text-center">
                        {user.last_name}
                      </td>
                      <td className="border border-collapse text-center">
                        {user.role && user.role.name}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
