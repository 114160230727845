import React, { useEffect, useState } from "react";
import { PlanificationsService } from "~/services/planificationsService";

const Rentabilite = (props) => {
  const persons = props.persons ? props.persons : [];
  const hoverId = props.hoverId ? props.hoverId : 0;
  const chantier = props.chantier ? props.chantier : {};
  const planifications = props.planifications ? props.planifications : [];
  const [costMO, setCostMO] = useState(0);
  const [countDays, setCountDays] = useState(0);

  // Création du service API et appel du hook utilisé pour la création de planifications
  const planificationsService = new PlanificationsService();
  const {
    data: apiGetDataPlanification,
    error: apiGetErrorPlanification,
    isLoading: apiGetIsLoadingPlanification,
    callToAction: apiGetHandlerPlanification,
  } = planificationsService.useGetAll(hoverId);

  useEffect(() => {
    if (apiGetDataPlanification && persons.length > 0) {
      let sum = 0;
      let countDays = 0;
      apiGetDataPlanification.map((item) => {
        let temp = 0;
        planifications.map((planification) => {
          if (
            planification.date == item.date &&
            planification.assigned_user_id == item.assigned_user_id
          ) {
            temp = temp + 1;
          }
        });

        countDays = countDays + 1 / temp;
        persons.map((person) => {
          if (person.id == item.assigned_user_id) {
            sum = sum + person.daily_rate / temp;
          }
        });
      });
      setCostMO(sum.toFixed(2));
      setCountDays(countDays.toFixed(2));
    }
  }, [apiGetDataPlanification]);

  const calcRenta = () => {
    const txAchats = 0;
    let renta = chantier.amount_no_taxes - costMO;
    return renta.toFixed(2);
  };

  const calcRentaFinal = () => {
    const txAchats = 30;
    let renta = chantier.amount_no_taxes - (chantier.amount_no_taxes * txAchats / 100) - costMO;
    return renta.toFixed(2);
  };

  if (apiGetIsLoadingPlanification) return "";

  return (
    <div>
      <ul>
        <li>
          Nombre de jours planifiés : {countDays}{" "}
          {chantier.man_days > 0 && `/ ${chantier.man_days} estimés`}
        </li>
        <li>Coût Main d'oeuvre : {costMO} €</li>
        <li>Rentabilité chantier après MO : {calcRenta()} HT ({((calcRenta()/chantier.amount_no_taxes)*100).toFixed(2)}%)</li>
        <li>
          Rentabilité chantier après MO et 30% d'achats: {calcRentaFinal()} HT ({((calcRentaFinal()/chantier.amount_no_taxes)*100).toFixed(2)}%)
        </li>
      </ul>
    </div>
  );
};

export default Rentabilite;
