import { useEffect } from "react"
import { useOutletContext } from "react-router-dom"
import { HashLoader } from "react-spinners"

export default function Index() {

    const [pageTitle, setPageTitle] = useOutletContext()

    useEffect(() => {
        setPageTitle("Accueil")
    }, [])

    return (
    <div className="w-full flex flex-col justify-center content-center">
        <HashLoader size={40} cssOverride={{margin: "0 auto 10px auto"}}/>
        <div className="text-center">Aucun contenu a afficher</div>
    </div>
    )
}