import React from 'react'
import ReactDOM from 'react-dom/client'
import { Routes } from '@generouted/react-router'

import './index.css'

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://42fe75138bb00a16c6899da7755e43d5@o4507974300598272.ingest.de.sentry.io/4507974302957648",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/*\.amhac\.fr/, /^https:\/\/*\.svc\.bstack\.fr/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // environment: (process.env.NODE_ENV && process.env.NODE_ENV === 'development') ? "local" : "compiled",
});

// createRoot(document.getElementById('root')).render(<Routes />)

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>,
)
