import React from "react";

const CardPlanif = (props) => {
  const planification = props.planification;
  const deletePlanning = props.deletePlanning ? props.deletePlanning : false;
  const editPlanning = props.editPlanning ? props.editPlanning : false;
  const setDeleteId = props.setDeleteId;
  const setUpdateId = props.setUpdateId;
  const updateId = props.updateId;
  const minimize = props.minimize ? props.minimize : false;
  const hoverId = props.hoverId ? props.hoverId : "";
  const setHoverId = props.setHoverId ? props.setHoverId : "";
  const personId = props.personId ? props.personId : "";
  const setPersonId = props.setPersonId ? props.setPersonId : "";

  return (
    <div
      draggable={editPlanning}
      style={{
        backgroundColor: `${
          hoverId == planification.chantier_id
            ? "#FFFFFF"
            : planification.chantier.color
        }`,
        border: `${
          hoverId == planification.chantier_id
            ? "4px solid " + planification.chantier.color
            : ""
        }`,
      }}
      className={`cursor-pointer rounded-lg shadow-lg flex flex-row items-center justify-evenly p-2 m-2 ${
        hoverId == planification.chantier_id && "text-black"
      }`}
      onClick={() => {
          setHoverId(
            updateId == planification.id &&
              hoverId == planification.chantier_id &&
              personId == planification.assigned_user_id
              ? ""
              : planification.chantier_id
          );
          setUpdateId(
            updateId == planification.id &&
              hoverId == planification.chantier_id &&
              personId == planification.assigned_user_id
              ? ""
              : planification.id
          );
          setPersonId(
            personId == planification.assigned_user_id &&
              updateId == planification.id &&
              hoverId == planification.chantier_id
              ? ""
              : planification.assigned_user_id
          );
      }}
    >
      <div className="flex text-sm">
        <span className="font-bold">
          {!minimize && planification.chantier.title}
        </span>
        <span className="ml-2">
          {planification.statut && planification.statut.split("~")[0]}
        </span>
        {deletePlanning && (
          <button onClick={() => setDeleteId(planification.id)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

export default CardPlanif;
