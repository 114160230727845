import { Link, Outlet, useLocation } from "react-router-dom";
import NavBar from "../components/NavBar";
import { UserContextProvider } from "../components/UserContext";
import { Modals } from "@generouted/react-router";
import LogRocket from "logrocket";
// LogRocket.init("gz5zk4/amac");

export default function App() {
  return (
    <div className="min-h-full h-screen">
      <UserContextProvider>
        <NavBar />
        <Outlet />
        <Modals />
      </UserContextProvider>
    </div>
  );
}
